<template>
  <div>
    <topDiv2 @show-my-fleet="handleShowMyFleet"></topDiv2>
    <searchFrame :isShowMyFleet="isShowMyFleet" @update:isShowMyFleet="isShowMyFleet = $event" />

  </div>
</template>



<script>
// import mapLayer from "@/components/mapLayer";
import topDiv2 from "@/components/topDiv2";
import searchFrame from "@/components/searchComponents/searchFrame";
export default {
  name: "test",
  components: {
    topDiv2,
    // mapLayer, // 注册 TopSection 组件
    searchFrame,
  },
  data() {
    return {
      // 添加一个本地数据属性来控制 searchFrame 的显示
      isShowMyFleet: false,
    };
  },
  methods:{
    handleShowMyFleet() {
      // 当 topDiv2 中的“我的船队”被点击时，更新属性
      this.isShowMyFleet = true;
      console.log("handle")
      console.log(this.isShowMyFleet)
    },
  }
}
</script>

<style scoped>

</style>
